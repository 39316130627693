.carouselWrapper {
    height: calc(100vh - 100px);
    width: 100%;
    background-size: cover;
}

.contentWrapper {
    padding: 136px 0 0 115px;
    width: 1150px;

    h1 {
        font-size: 72px;
        font-weight: 700;
        line-height: 64.8px;
        letter-spacing: -0.02em;
        text-align: left;
        text-transform: uppercase;
        width: 928px;
        margin-bottom: 23px;
    }

    p {
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 36px;
        padding-left: 6px;
        width: 540px;
    }
}

.productButton {
    padding: 16px 24px;
    margin-left: 6px;
    border: none;
    border-radius: 6px;
    background: var(--ds-main-red);
    width: 203px;
    height: 56px;

    span {
        color: var(--ds-main-white);
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        letter-spacing: 0;
    }
}

@media (max-width: 1359px) {
    .carouselWrapper {
        height: 480px;
        background-size: 100% 100%;
    }

    .contentWrapper {
        padding: 103px 0 0 46px;
        width: 600px;

        h1 {
            font-size: 42px;
            line-height: 42px;
            width: 541px;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            width: 438px;
            padding-left: 2px;
            margin-bottom: 40px;
        }
    }

    .productButton {
        padding: 16px 20px;
        margin-left: 2px;
        border-radius: 4px;
        width: 196px;
    }
}

@media (max-width: 767px) {
    .carouselWrapper {
        height: 576px;
        margin-top: 33px;
    }

    .contentWrapper {
        padding: 3px 0 0 18px;
        width: 267px;

        h1 {
            font-size: 30px;
            line-height: 30px;
            letter-spacing: -0.01em;
            margin-bottom: 10px;
            width: 324px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 24px;
            max-width: 100%;
        }
    }

    .productButton {
        padding: 12px 20px;
        width: 177px;
        height: 48px;

        span {
            font-size: 14px;
        }
    }
}
