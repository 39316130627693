.root {
    &[data-variant='cancel'] {
        background-color: #a5b1c0;
        color: #ffffff;
    }

    &[data-size='large'] {
        height: 48px;
        padding: 16px 10px;
    }

    &[data-size='small'] {
        height: 36px;
        padding: 10px 20px;
    }

    &[data-variant='primary'] {
        font-size: 13px;
        line-height: 16px;
        font-weight: 350;
        background-color: #2f9ceb;
        color: #ffffff;
    }

    &[data-variant='primary']:hover {
        background: #2a90d8;
    }

    &[data-variant='primary']:active {
        background: #2a90d8;
        transform: none;
        color: rgba(255, 255, 255, 0.7);
    }

    &[data-variant='primary']:disabled {
        background: #2f9ceb;
        opacity: 0.4;
    }

    &[data-variant='cancel'] {
        font-size: 13px;
        line-height: 16px;
        font-weight: 350;
        background-color: #a5b1c0;
        color: #ffffff;
    }

    &[data-variant='cancel']:hover {
        background: #919cab;
    }

    &[data-variant='cancel']:active {
        background: #919cab;
        transform: none;
        color: rgba(255, 255, 255, 0.7);
    }

    &[data-variant='cancel']:disabled {
        background: #a5b1c0;
        opacity: 0.4;
    }

    &[data-variant='outline'] {
        font-size: 13px;
        line-height: 16px;
        font-weight: 350;
        background: #fff;
        border: 1px solid #d8e1eb;
        color: #14171f;
    }

    &[data-variant='outline']:hover {
        background: #eaf5fd;
        border: 1px solid #2f9ceb;
    }

    &[data-variant='outline']:active {
        background: #eaf5fd;
        border: 1px solid #2f9ceb;
        transform: none;
        opacity: 0.7;
    }

    &[data-variant='outline']:disabled {
        background: rgba(216, 225, 235, 0.3);
        border: 1px solid #d8e1eb;
        opacity: 0.4;
    }

    &[data-disabled='true'] {
        background: rgba(216, 225, 235, 0.3);
        border: 1px solid #d8e1eb;
        opacity: 0.4;
    }

    .label {
        color: inherit;
    }

    .inner {
        color: inherit;
    }
}

.section:where([data-position='right']) {
    margin-inline-start: 8px;
}

.section:where([data-position='left']) {
    margin-inline-end: 8px;
}
