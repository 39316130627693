.control {
    background-color: rgba(216, 225, 235, 0.2);
    border: 1px solid #d8e1eb;
    color: #8a96a6;
    font-size: 14px;
}

.control[data-active]:not([data-disabled]) {
    background-color: #636e80;
    border: 0;
    color: #ffffff;
}

.dots {
    background-color: rgba(216, 225, 235, 0.2);
    border: 1px solid #d8e1eb;
    color: #8a96a6;
    border-radius: 4px;
}
