.form {
    padding: 48px;
    height: fit-content;

    & > h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
    }

    & p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
    }

    & > button {
        padding: 12px 24px;
        border-radius: 6px;
        background-color: var(--ds-main-black);
        border: 1px solid var(--ds-main-black);

        & > span {
            font-weight: 350;
            font-size: 16px;
            line-height: 18px;
            color: var(--ds-main-white);
        }
    }
}

.selectRegistrationForm {
    width: 480px;

    & > h2 {
        margin-bottom: 24px;
    }
    & > p {
        margin-bottom: 32px;
    }

    & > button {
        height: 56px;
        border: 1px solid var(--ds-main-gray);
        padding: 16px 30px 16px 20px;
        display: flex;
        background-color: var(--ds-main-white);
        gap: 8px;
        width: 100%;
        margin-bottom: 12px;

        & > span {
            color: var(--ds-main-black);
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
        }
    }

    & > div {
        margin-top: 24px;
        & > .checkbox {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            margin-right: 12px;
            outline: none;
        }

        & span {
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;

            & > span {
                color: var(--ds-main-blue);
                text-decoration: underline;
            }
        }
    }
}

.errorBlock {
    padding: 13px 16px;
    color: var(--ds-main-red);
    background-color: var(--ds-bg-light-red);
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    position: relative;
    border-radius: 2px 6px;
    overflow: hidden;
    margin-bottom: 32px;
}

.errorBlock:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: var(--ds-main-red);
}

@media (max-width: 767px) {
    .selectRegistrationForm {
        width: 100%;

        & > button {
            height: 56px;
            background-color: var(--ds-main-white);

            & > span {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
