.SearchWrapper {
    position: relative;
    width: 0;
    min-width: 40px;
    float: right;
    overflow: hidden;
}

.AutoCompleteWrapper {
    position: relative;
    right: -14px;
    width: 500px;
    transition: all 300ms ease-out;

    & :is(span, input) {
        width: 100%;
        height: 42px;
    }

    & :is(input, input:hover, input:focus) {
        border: 1px solid var(--ds-border-gray);
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
        color: var(--ds-main-black);
        box-shadow: none;
        padding-left: 18px;
    }

    & input::placeholder {
        color: var(--ds-main-gray);
    }
}

.SearchIcon {
    position: absolute;
    right: 14px;
    top: 8px;
}

.PanelWrapper {
    width: 500px;
    padding: 24px;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    display: grid;
    gap: 37px;

    & ul {
        display: grid;
        gap: 37px;
    }

    & li {
        display: grid;
        gap: 13px;
    }

    & :is(ul, li) {
        padding: 0;
        color: var(--ds-main-black);
    }

    & > div:last-child {
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-blue);
        cursor: pointer;
    }
}

.ItemTitle,
.ItemDescription {
    letter-spacing: 0;
    text-align: left;
}

.ItemTitle {
    font-size: 18px;
    font-weight: 350;
    line-height: 20px;
}

.ItemDescription {
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    white-space: initial;
}

.FooterWrapper {
    display: flex;
}

.ArrowStyle {
    width: 18px;
    height: 18px;
    color: var(--ds-main-gray);
    margin-right: 6px;
}

@media (max-width: 767px) {
    .AutoCompleteWrapper {
        position: absolute;
        width: calc(100% - 48px);
        right: 0;
        left: 24px;
    }

    .PanelWrapper {
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin-top: 8px;

        & li {
            gap: 14px;
        }
    }

    .ItemTitle {
        font-size: 16px;
    }

    .ItemDescription {
        font-size: 14px;
    }

    .BackgroundFilter {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        margin-top: 8px;
        opacity: 0.5;
        background: var(--ds-main-black);
        z-index: 2;
    }

    .FooterImage {
        position: absolute;
        width: 100%;
        height: 216px;
        left: 0;
        margin-top: 24px;
        background: url('../../../../../shared/images/svg/SearchResultBg.svg') no-repeat;
        background-size: cover;
    }
}
