.navigationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 0 62px;
    position: relative;

    & * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    & > svg:first-child {
        cursor: pointer;
    }
}

.navigationPanelWrapper {
    display: flex;
    align-items: center;
    height: 42px;
    width: 100%;

    & .navigationLInksBlock {
        flex: 1;
        display: flex;
        justify-content: space-between;
    }

    & nav {
        position: relative;
        left: 0;
        transition: all 300ms ease-out;
        padding-right: 11px;

        & ul {
            list-style: none;
            display: flex;
            gap: 24px;

            & a {
                text-decoration: none;
            }

            .navigationItem {
                color: var(--ds-main-black);
                font-size: 16px;
                font-weight: 350;
                line-height: 24px;
                letter-spacing: 0.02em;
                text-align: left;
                margin: 10px -12px 0 0; //todo: added for april release
            }
        }
    }
}

.hideNavigationList {
    opacity: 0;
    display: none;
    transition: all 300ms ease-out;
}

.navigationIcons {
    display: flex;
    gap: 16px;

    svg:first-child {
        width: 50px;
    }
}

.divider {
    height: 36px;
    width: 1px;
    background: var(--ds-border-gray);
    margin: 0 24px;
}

.navigationButtons {
    display: flex;
    gap: 12px;

    > button {
        height: 36px;
        padding: 0 18px;
        border-radius: 4px;

        &:focus {
            box-shadow: none;
        }

        > span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
        }

        &.whiteBtn {
            background: var(--ds-main-white);
            border: 1px solid var(--ds-main-gray);

            > span {
                color: var(--ds-main-black);
            }
        }

        &.redBtn {
            width: 90px;
            background: var(--ds-main-red);
            border: none;

            > span {
                color: var(--ds-main-white);
            }
        }
    }
}

.burgerMenuButton {
    cursor: pointer;
}

.overlayUserPanel {
    border: 1px solid var(--ds-border-gray);
    box-shadow: 3px 6px 0 0 rgba(60, 95, 138, 0.1);

    & * {
        margin: 0;
        padding: 0;
    }

    &:before {
        content: none;
    }

    &:after {
        content: none;
    }
}

.userAvatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 8px;
    object-fit: cover;
    cursor: pointer;
}

.headerUnderline {
    display: block;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 36px);
    margin: 0 18px;
}

@media (max-width: 1359px) {
    .navigationWrapper {
        > svg {
            width: 115px;
            height: 20px;
        }
    }

    .navigationWrapper {
        height: 60px;
        padding: 0 24px;
    }

    .navigationIcons {
        padding: 0;
        align-items: center;
        gap: 14px;
    }
}

@media (max-width: 1023px) {
    .navigationWrapper {
        padding: 0 24px;
    }
}

@media (max-width: 767px) {
    .navigationWrapper {
        padding: 0 18px;
    }
}

.hide {
    display: none;
}

.withHiddenNavigation {
    & nav {
        display: none;
    }
}
