@font-face {
    font-family: 'FavoritPro';
    src:
        local('FavoritPro'),
        url(./FavoritPro-Regular.woff2) format('opentype');
}
@font-face {
    font-family: 'FavoritPro';
    font-weight: 700;
    src:
        local('FavoritPro'),
        url(./FavoritPro-Bold.woff2) format('opentype');
}
@font-face {
    font-family: 'FavoritPro';
    font-weight: 350;
    src:
        local('FavoritPro'),
        url(./FavoritPro-Book.woff2) format('opentype');
}
@font-face {
    font-family: 'FavoritPro';
    font-weight: 500;
    src:
        local('FavoritPro'),
        url(./FavoritPro-Medium.woff2) format('opentype');
}
@font-face {
    font-family: 'FavoritPro';
    font-weight: 300;
    src:
        local('FavoritPro'),
        url(./FavoritPro-Light.woff2) format('opentype');
}

@font-face {
    font-family: 'Inter';
    src:
        local('Inter'),
        url(./Inter-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src:
        local('Inter'),
        url(./Inter-SemiBold.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 400;
    src:
        local('Inter'),
        url(./RobotoMono-Regular.ttf) format('opentype');
}
