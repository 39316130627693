.articleCardWrapper {
    padding: 30px 0 24px;
    height: 240px;

    > p {
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.1px;
        text-align: left;
    }
}

.articleBorder {
    border-bottom: 1px solid var(--ds-border-gray);
}

.articleImage {
    padding: 30px 36px 24px;
    border-radius: 6px;

    > p {
        color: var(--ds-main-white);
    }
}

@media (max-width: 1359px) {
    .articleCardWrapper {
        height: 180px;
        padding: 21px 5px 18px;

        > p {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0;
        }
    }

    .articleImage {
        padding: 21px 24px 18px;
    }
}

@media (max-width: 1023px) {
    .articleCardWrapper {
        padding: 21px 0 18px;
    }

    .articleImage {
        padding: 21px 24px 18px;
    }
}

@media (max-width: 767px) {
    .articleCardWrapper {
        width: 254px;
        height: 160px;
        padding: 15px 0 18px;

        > p {
            font-size: 15px;
        }
    }

    .articleImage {
        padding: 15px 18px 18px;
        border-radius: 4px;
    }
}
