.pointsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.point {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    border: 1px solid var(--ds-main-gray);
    cursor: pointer;
}

.activePoint {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: var(--ds-main-red);
    cursor: pointer;
}
