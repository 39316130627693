.errorPageWrapper {
    position: relative;
    height: calc(100vh - 100px);
    width: 100%;
    background:
        url('../../shared/images/png/technicSquaresBg.png') top left / auto 100% no-repeat,
        url('../../shared/images/png/technicBg.png') center / 100% 100% no-repeat;
}

.pageContent {
    padding: 156px 0 0 114px;
    width: 1100px;

    h1 {
        font-size: 80px;
        font-weight: 700;
        line-height: 80px;
        letter-spacing: -0.02em;
        text-align: left;

        span {
            font-size: 80px;
            font-weight: 700;
            line-height: 64px;
            letter-spacing: -0.02em;
            text-align: left;
            color: var(--ds-main-red);
        }
    }
}

@media (max-width: 1359px) {
    .errorPageWrapper {
        height: 480px;
    }

    .pageContent {
        padding: 91px 46px;
        width: 800px;

        h1 {
            font-size: 42px;
            line-height: 42px;

            span {
                font-size: 42px;
                line-height: 42px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .errorPageWrapper {
        margin-bottom: 100px;
    }

    .pageContent {
        padding: 145px 0 0 48px;
    }
}

@media (max-width: 767px) {
    .errorPageWrapper {
        display: block;
        height: 576px;
        margin-bottom: 180px;
        background:
            url('../../shared/images/png/technicSquaresBgSm.png') top left / 100% auto no-repeat,
            url('../../shared/images/png/technicBgSm.png') center / 100% 100% no-repeat;
    }

    .pageContent {
        padding: 35px 18px;
        width: 100%;

        h1 {
            font-size: 30px;
            line-height: 30px;

            span {
                font-size: 30px;
                line-height: 30px;
            }
        }
    }
}
