.sidebar {
    width: 250px;
    padding: 20px 20px 20px 0;
    border: none;
    background: #00000000;
    display: flex;
    align-items: flex-start;

    span {
        font-size: 22px;
    }
}

.item {
    display: flex;
    font-size: 24px;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    gap: 14px;
}
