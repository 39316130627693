.accountCardWrapper {
    width: 453px;
    min-height: 390px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    position: relative;
    overflow: hidden;
}

.blueTop {
    width: 453px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    height: 66px;
    background: var(--ds-bg-pale-blue);
}

.cardInnerWrapper {
    border-radius: 6px;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 30px 36px 36px 36px;

    & > .imgWrapper {
        position: absolute;
        top: 30px;
        border-radius: 90px;
        width: 72px;
        height: 72px;
        margin-bottom: 22px;

        & > img {
            width: 100%;
            height: 100%;
            border-radius: 90px;
            cursor: pointer;
            object-fit: cover;
        }

        & > svg {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    & > p {
        font-size: 18px;
        font-style: normal;
        font-weight: 350;
        line-height: 24px;
        margin-top: 22px;
    }

    & > span {
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 18px;
        color: var(--ds-main-gray);
    }
}

.greyLine {
    height: 1px;
    background-color: var(--ds-border-gray);
    width: 100%;
    margin-top: 30px;
    margin-bottom: 18px;
}

.infoWrapper {
    width: 100%;
    margin-bottom: 17px;
    & > img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    & > p {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 200px;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & > p + p {
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        color: var(--ds-main-blue);
        text-decoration: underline dashed;
        cursor: pointer;
        margin-left: 12px;
    }
}

.editButton {
    padding: 9px 24px 9px 12px;
    gap: 6px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--ds-main-gray);
    background-color: var(--ds-main-white);
    margin-top: 17px;
    height: 42px;

    & > img {
        width: 24px;
        height: 24px;
    }

    & > span {
        font-size: 16px;
        font-style: normal;
        font-weight: 350;
        line-height: 18px;
        color: var(--ds-main-black);
    }

    &.redBtn {
        border: none;
        background-color: var(--ds-main-red);

        & > span {
            color: var(--ds-main-white);
        }
    }
}

.getInfoModal {
    width: 480px;
}

@media (max-width: 1599px) {
    .accountCardWrapper {
        width: 476px;
    }
    .blueTop {
        width: 476px;
    }
}

@media (max-width: 1023px) {
    .accountCardWrapper {
        width: 100%;
    }
    .blueTop {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .accountCardWrapper {
        height: auto;
        min-height: auto;
    }

    .blueTop {
        height: 54px;
    }

    .cardInnerWrapper {
        padding: 24px 22px 18px 23px;
        height: auto;

        & > .imgWrapper {
            width: 60px;
            height: 60px;
            margin-bottom: 13px;
        }

        & > p {
            font-size: 15px;
            font-weight: 350;
            line-height: 24px;
        }

        & > span {
            font-size: 12px;
            font-weight: 350;
            line-height: 14px;
        }
    }

    .greyLine {
        margin-top: 20px;
        margin-bottom: 22px;
    }

    .infoWrapper {
        margin-bottom: 13px;
        margin-left: 35px;

        & > img {
            width: 18px;
            height: 18px;
        }

        & > p {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .editButton {
        margin-top: 12px;
        & > img {
        }
        & > span {
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 18px;
        }
    }

    .getInfoModal {
        width: calc(100% - 60px);
    }

    .modalWrapper {
        width: calc(100% - 60px);
    }
}
