.functionList {
    width: 100%;
    padding: 25px 36px !important;
    min-width: 300px;

    & > li {
        width: 100%;
        margin-bottom: 16px;
        cursor: pointer;

        & > a {
            width: 100%;
        }

        & > * {
            text-decoration: none;
        }

        &:last-child {
            margin-bottom: 0;
        }

        & p {
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 24px;
        }
    }

    & > div {
        height: 1px;
        width: 100%;
        background-color: var(--ds-border-gray);
        margin-bottom: 25px;
    }
}
.logoutWrapper {
    gap: 12px;
}

.cartCount {
    height: 24px;
    padding: 2px 10px;
    border-radius: 12px;
    background: var(--ds-main-red);

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
    color: var(--ds-main-white);
}
