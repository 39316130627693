.headerWrapper {
    background: var(--ds-main-black);
    height: 36px;
    display: flex;
    justify-content: center;

    a {
        text-decoration: none;

        display: flex;
        align-items: center;
    }

    & > * {
        width: 100%;
        height: 36px;
        padding: 0 21px;
        cursor: auto;

        & > * {
            cursor: pointer;
        }
    }
}

.linkWrapper {
    max-width: 1930px;
    display: flex;
    align-items: center;

    gap: 21px;
}

.title {
    margin-left: 15px;
    font-size: 11px;
    font-weight: 350;
    line-height: 12px;
    letter-spacing: -0.1px;
    text-align: left;
    color: var(--ds-main-white);
}

.icon {
    width: 13px;
    height: 13px;
}

@media screen and (max-width: 1929px) {
    .headerWrapper {
        display: block;
        padding: unset;
        & > * {
            max-width: unset;
        }
    }
}

@media (max-width: 1023px) {
    .title {
        letter-spacing: -0.07px;
    }
}

@media (max-width: 767px) {
    .headerWrapper {
        margin-top: 32px;
    }
}
