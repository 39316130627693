@import '../styles.module';

.menuWrapper {
    display: none;
}

.cartCount {
    height: 24px;
    padding: 2px 10px;
    border-radius: 12px;
    background: var(--ds-main-red);

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
    color: var(--ds-main-white);
}

@media (max-width: 1359px) {
    .menuWrapper {
        display: block;
        position: absolute;
        top: 60px;
        width: 100%;
        left: 0;
        background: var(--ds-main-white);
        z-index: 10;

        & > hr {
            background: var(--ds-border-gray);
            height: 1px;
            margin: 0 25px 0 36px;
        }

        & > ul {
            margin: 28px 25px 28px 36px;
            list-style-type: none;
            gap: 35px;

            & > li {
                font-size: 18px;
                font-weight: 350;
                line-height: 24px; /* 133.333% */
                letter-spacing: 0.36px;
                cursor: pointer;
                display: inline-flex;

                & > a {
                    text-decoration: none;
                    color: var(--ds-main-black);
                }

                &.logout {
                    gap: 12px;
                    color: var(--ds-main-gray);
                    cursor: not-allowed;
                }
            }
        }

        & > .backgroundFilter {
            position: absolute;
            bottom: -100%;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background: var(--ds-main-black);
        }
    }

    .navigationButtons.buttonsInMenu {
        display: flex;
        padding: 0;
        gap: 12px;
        margin: 28px 25px 28px 36px;

        & > button {
            width: 156px;

            & > span {
                text-align: center;
            }
        }
    }
}

.userFunctionsListWrapper {
    width: 100%;

    & > li,
    & > a {
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;
        text-decoration: none;

        &:last-child {
            margin-bottom: 0;
        }

        & > p {
            font-size: 18px;
            font-weight: 350;
            line-height: 24px;
            letter-spacing: 0.36px;
        }
    }
}
