.tagButtonWrapper {
    border: none;
    border-radius: 4px;
    display: inline-block;
    padding: 5px 16px;

    span {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
    }
}

.black {
    background: var(--ds-main-black);
    span {
        color: var(--ds-main-white);
    }
}

.gray {
    background: var(--ds-bg-light-gray);
    span {
        color: var(--ds-main-gray);
    }
}

@media (max-width: 1359px) {
    .tagButtonWrapper {
        padding: 4px 16px;

        span {
            line-height: 20px;
        }
    }
}

@media (max-width: 767px) {
    .tagButtonWrapper {
        padding: 2px 10px;

        span {
            font-size: 13px;
            font-weight: 400;
        }
    }
}
